import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { arrayMove } from 'baseui/dnd-list';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Portal from './portal';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { DragDropContext } from 'react-beautiful-dnd';
import AddLocationHomeComponent from './addLocationHomeComponent';
import { sourceByType } from '../../util';
import styled from '@emotion/styled';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import { getUpdateMutationsForHomeComponent } from '../builder/builderUtils/save';
import { client } from '../../apollo/client';
import EditHomeBackComponent from './editCardBackHomeComponent';
import Toggle from 'react-toggle';
import { StatefulTooltip } from 'baseui/tooltip';
import 'react-toggle/style.css';
import { showAlert } from '../../redux/actions/appBasicControls';
import { Label3 } from 'baseui/typography';
import { connect } from 'react-redux';
import { Tag, VARIANT } from 'baseui/tag';


const LocationModule = ({
  selectedComponent,
  appContentScopeId,
  organization_id,
  refetchHomeComponents,
  tabFilter,
  dispatch,
  defaultTheme,
  searchText: globalSearchText,
  jwPlayerData,
  libraries,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const [modules, setModules] = React.useState([]);
  const componentType = selectedComponent.home_component.component_type;
  const currentComponentConfig = CardBuilderOptions.find((el) => el.__typename == componentType);
  const updateMutation = getUpdateMutationsForHomeComponent(currentComponentConfig);

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [selectedModule, setSelectedModule] = React.useState(null);

  const [isLoading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const updatePosition = async (id, position) => {
    await client.mutate({
      mutation: updateMutation,
      variables: { id, position }
    });
    refetchHomeComponents();
    return;
  };

  const updatePublished = async (id, published, data = {}) => {
    let o = data || {};
    Object.keys(o).forEach(el => {
      if (typeof o[el] === 'object') {
        if (Array.isArray(o[el])) {
          o[el] = o[el].map(el1 => {
            delete el1.__typename;
            return el1;
          })
        } else {
          console.log({ o, el })
          if (o && el && o[el] && o[el].__typename) {
            delete o[el].__typename;
          }
        }
      }
    });

    dispatch(showAlert({
      msg: 'Updating Home Component'
    }))
    await client.mutate({
      mutation: updateMutation,
      variables: {
        id,
        ...data,
        published
      }
    });
    await refetchHomeComponents();
    setLoading(false);
    // .then(() => {
    dispatch(showAlert({
      msg: 'Home Component Updated'
    }))
    // })
    return;
  };

  const onClose = () => {
    setIsEditOpen(false);
    setSelectedModule(null);
  }

  const onToggleClick = (e, location) => {
    e.preventDefault();
    e.stopPropagation();
    updatePublished(location.content_module.id, e.target.checked, location.content_module);
    let m = JSON.parse(JSON.stringify(modules));
    let indexToUpdate = m.findIndex(m0 => m0.content_module.id == location.content_module.id);
    if (m[indexToUpdate]) {
      m[indexToUpdate].published = e.target.checked;
    }
    setModules(m);

  }



  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(selectedComponent.home_component.card_content_modules));
    s = s.filter((el) => {
      if (tabFilter === 'published') {
        return el.published == true && el.archived !== true;
      }
      if (tabFilter === 'archived') {
        return el.archived === true
      }
      if (tabFilter === 'all') {
        return el.archived !== true
      }
      return true;
    });
    setModules([...s].sort((a, b) => a.position - b.position));
  }, [selectedComponent]);

  const onSearchLocation = e => {
    console.log('onSearchLocation', e.target.value)
    setSearchText(e.target.value)
  }

  const renderLocationTableItem = useCallback((provided, snapshot, index, location) => {
    const {
      content_module
    } = location || []

    const selectedTags = (props.organizationTags || []).filter(tag => (content_module?.tag_ids || []).includes(tag.id));

    // console.log('renderLocationTableItem', { location, organizationTags: props.organizationTags, selectedTags, tagIds: content_module?.tagIds })


    return <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        width: '100%',
        minHeight: '64px',
        background: snapshot.isDragging ? 'rgb(128 128 128 / 40%)' : 'rgb(234, 234, 234)',
        padding: '16px',
        borderRadius: '10px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        ...provided.draggableProps.style
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '60px' }}>
            <img
              className={css({
                height: '44px',
                backgroundSize: 'cover !important',
                position: 'relative',
                float: 'left',
                borderRadius: '8px',
                marginRight: '16px',
                marginBottom: '0px',
              })}
              src={content_module.cover_image ? sourceByType(content_module.cover_image, 'full', 'full') : 'https://img.icons8.com/plumpy/48/000000/map-marker.png'}
            />
          </div>
          <Label3>{content_module?.name || content_module?.title}</Label3>
          <div
            style={{
              display: 'flex',
              flex: 1,
              marginLeft: '10px',
              flexWrap: 'wrap'
            }}
          >
            {(selectedTags || []).map((el, index) => {
              return (
                <Tag
                  key={`${el.id}-${index}`}
                  closeable={false}
                  variant={VARIANT.solid}
                >
                  {el?.name || ''}
                </Tag>
              )
            })}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer', opacity: 1, marginRight: '16px' }}
            onClick={() => {
              setSelectedModule(location);
              setIsEditOpen(true);
            }}
          >
            <path
              d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
              fill={'black'}
            />
          </svg>
          <StatefulTooltip
            content={() => (
              <div padding={'20px'}>{location.published ? 'Toggle off to Publish' : 'Toggle on to Publish'}</div>
            )}
            returnFocus
            autoFocus
          >
            <div
              className={css({
                width: theme.sizing.scale1200,
              })}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Toggle
                id="cheese-status"
                checked={location.published}
                icons={false}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={e => {
                  console.log('toggle change')
                  if (isLoading) {
                    return true;
                  }
                  e.preventDefault();
                  e.stopPropagation();
                  if (tabFilter === "archived") {
                    return props.dispatch(
                      showAlert({
                        msg: 'Archived content can\'t be published!',
                        error: true,
                      })
                    );
                  }
                  // setLoading(true);
                  onToggleClick(e, location);
                }}
              />
            </div>
          </StatefulTooltip>

        </div>
      </div>


    </div>
  }, [props.organizationTags, modules])

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'} >
        <div
          style={{
            width: '100%',
            paddingTop: theme.sizing.scale600,
          }}
        >
          <AddLocationHomeComponent
            position={modules.length}
            app_content_scope_id={appContentScopeId}
            organization_id={organization_id}
            home_component_id={selectedComponent.home_component.id}
            refetchHomeComponents={refetchHomeComponents}
            selectedComponentType={currentComponentConfig}
            jwPlayerData={jwPlayerData}
            libraries={libraries}
            dispatch={dispatch}
            onSearch={onSearchLocation}
          ></AddLocationHomeComponent>
        </div>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <div id={`wrapper_deck${selectedComponent.id}`} className="wrapper" style={{
            // paddingTop: theme.sizing.scale600,
            minHeight: '274px',
            maxHeight: '70vh',
            overflowY: 'auto'
          }}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >

              <DragDropContext
                onDragEnd={({ destination, source, ...rest }) => {
                  if (!destination) {
                    return;
                  }
                  let newArr = arrayMove(
                    JSON.parse(JSON.stringify(modules)),
                    source.index,
                    destination.index
                  );
                  newArr = newArr.map((el, i) => {
                    el.position = i;
                    delete el.isNew;
                    return el;
                  });
                  newArr.forEach(el => {
                    updatePosition(el.content_module.id, el.position);
                  });
                  setModules(newArr);
                }}
              >
                <Droppable
                  droppableId={selectedComponent.id}
                  type={'HOME_COMPONENT'}
                  // direction="horizontal"
                  isCombineEnabled={false}
                >
                  {dropProvided => (
                    <div
                      {...dropProvided.droppableProps}
                      className="scrolls"
                      ref={dropProvided.innerRef}
                      style={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      {modules.map((el, index) => {

                        let isInSearch;
                        if ((globalSearchText || searchText) && el.content_module.name) {
                          isInSearch = el.content_module.name.toLowerCase().indexOf((globalSearchText || '').toLowerCase()) ||
                            el.content_module.name.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1;
                          if (!isInSearch) return;
                        }
                        console.log('modules ----------------------------------------------------------------', { globalSearchText, searchText, content_module: el.content_module, isInSearch })
                        return (
                          <Draggable
                            key={el.content_module.id}
                            draggableId={el.content_module.id}
                            index={index}
                            isDragDisabled={isInSearch}
                          >
                            {(dragProvided, snapshot) => (
                              <Portal
                                snapshot={snapshot}
                                child={renderLocationTableItem(dragProvided, snapshot, index, el)}
                              ></Portal>
                            )}
                          </Draggable>
                        );
                      })}

                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </Cell>
      </Grid>
      {isEditOpen && <EditHomeBackComponent
        app_content_scope_id={appContentScopeId}
        organization_id={organization_id}
        home_component_id={selectedComponent.home_component.id}
        refetchHomeComponents={refetchHomeComponents}
        selectedComponentType={currentComponentConfig}
        selectedModule={selectedModule}
        onClose={onClose}
        updateMutation={updateMutation}
        jwPlayerData={jwPlayerData}
        libraries={libraries}
        dispatch={dispatch}
      ></EditHomeBackComponent>}
    </>
  )
}

const mapStateToProps = props => {
  let { organization, organizationTags } = props.appBasicControls;
  return {
    organization,
    organizationTags
  };
};

export default connect(mapStateToProps)(LocationModule)